import '../styles/Lightool-page.css'
import '../App.css'
import React, { useEffect, useState } from "react";

function Lightool() {
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        const videoElement = document.getElementById('myVideo');

        const handleFullscreenChange = () => {
            if (document.fullscreenElement || document.webkitFullscreenElement) {
                setIsFullscreen(true);
                videoElement.controls = true;
            } else {
                setIsFullscreen(false);
                videoElement.controls = false;
            }
        };

        videoElement.addEventListener('mouseover', () => {
            if (!isFullscreen) {
                videoElement.controls = true;
            }
        });

        videoElement.addEventListener('mouseout', () => {
            if (!isFullscreen) {
                videoElement.controls = false;
            }
        });

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        };
    }, [isFullscreen]);

    useEffect(() => {
        const videoElement = document.getElementById('myVideo2');

        const handleFullscreenChange = () => {
            if (document.fullscreenElement || document.webkitFullscreenElement) {
                setIsFullscreen(true);
                videoElement.controls = true;
            } else {
                setIsFullscreen(false);
                videoElement.controls = false;
            }
        };

        videoElement.addEventListener('mouseover', () => {
            if (!isFullscreen) {
                videoElement.controls = true;
            }
        });

        videoElement.addEventListener('mouseout', () => {
            if (!isFullscreen) {
                videoElement.controls = false;
            }
        });

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        };
    }, [isFullscreen]);

    return (
        <div className="container">
            <div className="Lightool">Lightool</div>

            <div className="background">
                <div className="margin">
                    <h1> Installation </h1>
                    <p className="para-tabulation" > Pour commencer vous devez ajouter notre plugin dans votre projet pour faire ça rien de plus simple. Attention ce plugin est pour Unreal 5.0.3. </p>
                    <div className="column-paragraphe-light-dossier">
                        <img src={require('../images/Image_Dossier_Lightool.png')} alt="Houdini" className="Image_Dossier_Lightool" />
                        <p className="para-tabulation-pp" > Aller dans la résine du projet, puis ouvrez le dans l’explorateur de fichier de votre ordinateur
                            Vous devriez avoir ces fichiers-là. Nous avons besoin d’un fichier “Plugins”, il est possible qu’il existe déjà, mais si vous ne l’avez pas vous allez devoir le créer. Faites attention, pour qu’Unreal comprenne que le fichier contient des plugins vous devez l’appeler “Plugins”.
                        </p>
                    </div>
                    <p className="para-tabulation" > Aller dans la résine du projet, puis ouvrez le dans l’explorateur de fichier de votre ordinateur
                        Vous devriez avoir ces fichiers-là. Nous avons besoin d’un fichier “Plugins”, il est possible qu’il existe déjà, mais si vous ne l’avez pas vous allez devoir le créer. Faites attention, pour qu’Unreal comprenne que le fichier contient des plugins vous devez l’appeler “Plugins”.
                    </p>
                    <h1> Emissive Material </h1>
                    <p className="para-tabulation" >
                        Une fois que vous avez installé notre plugin il faut que vous assuriez que vôtre asset contenaient un émissive matériel, vous pouvez  en ajouter un directement dans Unreal ou fournir le vôtre.
                    </p>
                    <img src={require('../images/Image_Emissive_Lightool.png')} alt="Houdini" className="Image_Emissive_Lightool" />
                    <p className="para-tabulation" >
                        Normalement vous devrez avoir quelque chose comme au-dessus, mais vous pouvez avoir juste un “émissive” cela marche aussi. Pour que le plugin reconnaisse que le matériel où vous avez mis l’émissive il faut que le Slot Name soit “LighEmissive”. Petit conseille pour vous faciliter la tâche, quand vous assignez un matériel sur model dans le logiciel 3D de votre choix, nommez le matériel “LightEmissive” le Slot Name prendra directement le Name qu’il faut.
                    </p>


                    <h1 className="H1" > Creation de l'asset </h1>
                    <h2> Partie create Light Asset </h2>
                    <img src={require('../images/Image_Create_Light_Asset_Lightool.png')} alt="Houdini" className="Image_Create_Light_Asset_Lightool" />
                    <h3> Light Asset Name </h3>
                    <p className="para-tabulation" > Insérez le nom que vous voulez pour votre light blueprint </p>
                    <h3> Light Asset Folder Path </h3>
                    <p className="para-tabulation" > Insérez l’emplacement du blueprint que vous allez créer, attention, vous ne pouvez pas utiliser d’espace dans la nomenclature de votre folder path. Utilisez “/” pour séparer les fichiers. </p>
                    <h3> Generate </h3>
                    <p className="para-tabulation" > Ensuite appuyez sur “générer” quand une fois fait ça vas vous ouvrir un page de blueprint ou vous allez pouvoir ajouter dans le static mesh que vous voulez et vous allez pouvoir également ajouter tout type de lighting que vous voulez. </p>
                    <img src={require('../images/Image_Generate_Light_Asset_Lightool.png')} alt="Houdini" className="Image_Generate_Light_Asset_Lightool" />
                    <p className="para-tabulation" > Une fois faits-vous avez plus de cas placer le BluePrint dans votre scène et illuminer votre niveau ! </p>

                    <h2> Partie Create Flicker </h2>
                    <img src={require('../images/Image_Create_Flicker_Lightool.png')} alt="Houdini" className="Image_Create_Flicker_Lightool" />
                    <h3> Light Flicker Name </h3>
                    <p className="para-tabulation" > Insérez le nom que vous voulez pour votre Flicker. </p>
                    <h3> Light Flicker Path </h3>
                    <p className="para-tabulation" > Insérez l’emplacement du flicker que vous allez créer attention, vous ne pouvez pas utiliser d’espace dans la nomenclature de votre folder path. Utilisez “/” pour séparer les fichiers. </p>
                    <h3> Genrale </h3>
                    <p className="para-tabulation" > Appuyez sur “Generate” pour ouvrir une curve, vous allez pouvoir l’ajuster.  </p>
                    <div className="column-paragraphe-light-dossier">
                        <img src={require('../images/Image_Create_Flicker_Generate_Lightool.png')} alt="Houdini" className="Image_Create_Flicker_Generate_Lightool" />
                        <div className="para-tabulation-pp">
                            <p className="FlickerIntensity5" > Quand vous avez fini, sauvegardez et quittez. Cela vas aussi vous produire un data asset où vous pouvez modifier les paramètre : </p>
                            <h4 className="FlickerIntensity4" > Flicker Intensity </h4>
                            <p className="FlickerIntensity4" > Permet de varier la puissance du flicker qui va être sur les éléments de votre blueprint. </p>
                            <h4 className="FlickerTimeOffset4" > Flicker Time Offset </h4>
                            <p className="FlickerTimeOffset4" > Change la longueur de chaque pulsation. Clignotement plus long. </p>
                            <h4 className="FlickerTimeScale4"> Flicker Time Scale </h4>
                            <p className="FlickerTimeScale4"> Augmente la répétition de la texture. La lumière clignotera plus rapidement. </p>
                        </div>
                    </div>
                    <p className="FlickerIntensity6" > Quand vous avez fini, sauvegardez et quittez. Cela vas aussi vous produire un data asset où vous pouvez modifier les paramètre : </p>
                    <h4 className="FlickerIntensity" > Flicker Intensity </h4>
                    <p className="FlickerIntensityp" > Permet de varier la puissance du flicker qui va être sur les éléments de votre blueprint. </p>
                    <h4 className="FlickerTimeOffset" > Flicker Time Offset </h4>
                    <p className="FlickerTimeOffsetp" > Change la longueur de chaque pulsation. Clignotement plus long. </p>
                    <h4 className="FlickerTimeScale"> Flicker Time Scale </h4>
                    <p className="FlickerTimeScalep" > Augmente la répétition de la texture. La lumière clignotera plus rapidement. </p>
                    <h3> Data </h3>
                    <p className="para-tabulation" > Ajoutez la texture que vous allez produire dans la prochaine étape. </p>
                    <h3> Generate Texture </h3>
                    <p className="para-tabulation" > “Generate Texture” produit une texture à partir de la courbe et va l’assigner au data asset. </p>
                    <h3> Refresh </h3>
                    <p className="para-tabulation" > À tout moment vous pouvez modifier la courbe et click sur
                        Refresh pour appliquer les changements.
                    </p>

                    <video
                        src={require('../videos/LighToolDemo.mp4')}
                        id="myVideo"
                        className="Video_LightTool"
                    ></video>

                    <video
                        src={require('../videos/LighTool_2.mp4')}
                        id="myVideo2"
                        className="Video_LightToolExample"
                    ></video>
                </div>
            </div>
        </div>
    );
}
export default Lightool;
