// pages/About.js
import '../styles/About.css';
import React from "react";

import unrealimage from '../images/Unreal.png';

function About() {
    return (
        <div className="About">
            <div className="about-background">
                <div className="about-container">
                    <div className="card-container-about">
                        <div className="card-software">
                            <div className="img-software">
                                <p className="Title-software"> SoftWare </p>
                            </div>
                            <div className="text-software">
                                <div className="image-text-container">
                                    <img src={require('../images/Houdini.png')} alt="Houdini" className="image" />
                                    <p className="software-name">Houdini</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/3DsMax.png')} alt="3Ds-Max" className="image" />
                                    <p className="software-name">3Ds Max</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Maya.png')} alt="Maya" className="image" />
                                    <p className="software-name">Maya</p>
                                </div>

                                <div className="image-text-container">
                                    <img src={require('../images/substance.png')} alt="Substance" className="image" />
                                    <p className="software-name">Substance</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={unrealimage} alt="Unreal" className="image"/>
                                    <p className="software-name">Unreal</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Unity.png')} alt="Unity" className="image" />
                                    <p className="software-name">Unity</p>
                                </div>

                                <div className="image-text-container">
                                    <img src={require('../images/SpeedTree.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">SpeedTree</p>
                                </div>

                                <div className="image-text-container">
                                    <img src={require('../images/Blender_logo_no_text.svg.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">Blender</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/zbrush_logo.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">zbrush</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Texte">
                        <div className="about">
                            <div className="container-about">
                                <h1 className="titre">Introduction</h1>
                                <p className="paragraphe">
                                    Passionné de jeux vidéo depuis mon enfance et récemment
                                    diplômé en Tech-Art, j'ai développé des compétences pour
                                    la programmation, et notamment pour le développement
                                    procédural sur Houdini et Unreal. Curieux et autodidacte,
                                    mes projets personnels et ma formation m’ont permis
                                    d’acquérir des bases solides et des compétences que je suis
                                    impatient de mettre en pratique pour contribuer au
                                    développement de jeux innovants et captivants dans
                                    l’industrie. </p>
                            </div>
                        </div>
                        <div className="about">
                            <div className="container-about">
                                <h1 className="titre">Experience</h1>
                                <p className="paragraphe">
                                    <p className='titre-par-about'>Projet fin d etude, Rubika</p>
                                    <p>• Lighting et Tooling dans unreal</p>
                                    <p>• Création d’un environnement 3D intérieur sur le
                                        thème Horreur
                                    </p>
                                    <p>• Création d’un environnement 3D extérieur sur
                                        le thème du Japon
                                    </p>
                                    <p className='titre-par-about'>Fevrier 2022 a juin 2023 Superviseur a temps partiel, Metro</p>
                                    <p>• Gérer et superviser jusqu'à 15 employés</p>
                                    <p>• Responsable des demandes et de la communication auprès des clients</p>
                                    <p>• Travailler en équipe pour assurer le bon fonctionnement de l'entreprise</p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="card-container-about">

                        <div className="card-software-bot">
                            <div className="img-software">
                                <p className="Title-software"> SoftWare </p>
                            </div>
                            <div className="text-software">
                                <div className="image-text-container">
                                    <img src={require('../images/Houdini.png')} alt="Houdini" className="image" />
                                    <p className="software-name">Houdini</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/3DsMax.png')} alt="3Ds-Max" className="image" />
                                    <p className="software-name">3Ds Max</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Maya.png')} alt="Maya" className="image" />
                                    <p className="software-name">Maya</p>
                                </div>

                                <div className="image-text-container">
                                    <img src={require('../images/substance.png')} alt="Substance" className="image" />
                                    <p className="software-name">Substance</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={unrealimage} alt="Unreal" className="image"/>
                                    <p className="software-name">Unreal</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Unity.png')} alt="Unity" className="image" />
                                    <p className="software-name">Unity</p>
                                </div>

                                <div className="image-text-container">
                                    <img src={require('../images/SpeedTree.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">SpeedTree</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Blender_logo_no_text.svg.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">Blender</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/zbrush_logo.png')} alt="SpeedTree" className="image" />
                                    <p className="software-name">zbrush</p>
                                </div>
                            </div>
                        </div>

                        <div className="card-language">
                            <div className="img-language">
                                <p className="Title-language"> Language </p>
                            </div>
                            <div className="text-software">
                                <div className="image-text-container">
                                    <img src={require('../images/Python.png')} alt="Python" className="image" />
                                    <p className="software-name">Python</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/React.png')} alt="React" className="image" />
                                    <p className="software-name">React</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/C++.png')} alt="React" className="image" />
                                    <p className="software-name">C++</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/C#.png')} alt="React" className="image" />
                                    <p className="software-name">C#</p>
                                </div>
                                <div className="image-text-container">
                                    <img src={require('../images/Java.png')} alt="React" className="image" />
                                    <p className="software-name">Java</p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>

);
}export default About;
