// pages/Home.js
import {Link} from 'react-router-dom';
import '../styles/Home.css'
import '../App.css'

import photoProfil from '../images/Photo-profil.png';

function Home() {
    return (
        <div className="Home">
            <div className='Profil'>
                <div className='profile-card'>
                    <div className='border-test'>
                        <img src={photoProfil}
                             alt="Profil"
                             className='img-profile-photo'/>
                    </div>
                    <p className='p-profile'> Passionné de jeux vidéo depuis mon enfance et récemment
                        diplômé en Tech-Art, j'ai développé des compétences pour
                        la programmation, et notamment pour le développement
                        procédural sur Houdini et Unreal. Curieux et autodidacte,
                        mes projets personnels et ma formation m’ont permis
                        d’acquérir des bases solides et des compétences que je suis
                        impatient de mettre en pratique pour contribuer au
                        développement de jeux innovants et captivants dans
                        l’industrie.
                    </p>
                    <Link className='lien' to="/about">
                        <p className='p-lien'> Vers about</p>
                    </Link>
                </div>
            </div>
            <div className="tooling">
                <p className='Titre-tolling'> Tooling </p>
                <div className="container-wrapper">
                    <div className="container-home">
                        <div className="card-container">
                            <div className="card">
                                <div className="front-content">
                                    <img src={require('../images/Collitool-image.png')}
                                         alt="3Ds-Max"
                                         className="img-collitool"/>
                                </div>
                                <div className="content">
                                    <div className='padding'>
                                        <p className="heading">Collitool</p>
                                        <p className='paragraphe-collitool'>
                                            Collitool, développé sur Unreal Engine, facilite l'ajout de
                                            collisions et d'assets, avec choix du type de collision (simple,
                                            complexe,
                                            convexe). En mode complexe, il utilise un mesh basse résolution pour
                                            optimiser
                                            les performances. Il permet également la suppression ou le
                                            remplacement de
                                            collisions, et l'application de matériaux spécifiques à la collision
                                            pour un
                                            rendu plus optimiser.
                                        </p>
                                        <Link className='link-collitool' to="/collitool">
                                            <p className='collitool-lien'> Voir plus </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-container">
                            <div className="card">
                                <div className="front-content">
                                    <img src={require('../images/Nomentool-image.png')}
                                         alt="3Ds-Max"
                                         className="img-collitool"/>
                                </div>
                                <div className="content">
                                    <div className='padding'>
                                        <p className="heading">Nomentool</p>
                                        <p className='paragraphe-collitool'>
                                            Nomentool, développé sur Unreal Engine, facilite la création de
                                            nomenclatures, l'ajout de préfixes aux assets et leur organisation
                                            dans des
                                            dossiers, améliorant ainsi efficacité et propreté de vos projets
                                            Unreal.
                                        </p>
                                        <Link className='link-collitool' to="/nomentool">
                                            <p className='collitool-lien'> Voir plus </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-container">
                            <div className="card">
                                <div className="front-content">
                                    <img src={require('../images/Lightool-image.png')}
                                         alt="3Ds-Max"
                                         className="img-collitool"/>
                                </div>
                                <div className="content">
                                    <div className='padding'>
                                        <p className="heading">Lightool</p>
                                        <p className='paragraphe-collitool'>
                                            Lightool, développé pour Unreal Engine, permet de gérer
                                            l'éclairage via un Blueprint Actor en ajustant couleur, intensité,
                                            température,
                                            et en créant des effets de scintillement. Il s'applique à la fois
                                            aux matériaux
                                            et aux lumières intégrés au Blueprint.
                                        </p>
                                        <Link className='link-collitool' to="/lightool">
                                            <p className='collitool-lien'> Voir plus </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-container">
                            <div className="card">
                                <div className="front-content">
                                    <img src={require('../images/Roadtool-image.png')}
                                         alt="3Ds-Max"
                                         className="img-collitool"/>
                                </div>
                                <div className="content">
                                    <div className='padding'>
                                        <p className="heading">Roadtool</p>
                                        <p className='paragraphe-collitool'>
                                            Roadtool, un outil en cours de développement sur Houdini pour
                                            Unreal, permet la création automatique de routes adaptées à tout
                                            type de
                                            terrain. Les ponts ne sont pas encore intégrés, mais les autres
                                            fonctionnalités
                                            fonctionnent bien.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modeling">
                <p className='Titre-modeling'> Modeling </p>
                <div className='container-modeling'>
                    <div className="card-modeling">
                        <div className="container-image-modeling">
                            <img src={require('../images/Bague_V3.png')}
                                 alt="3Ds-Max"
                                 className="image-circle-modeling"/>
                        </div>
                        <div className="content-modeling">
                            <div className="detail-modeling">
                                <div className='p-card-modeling'>
                                    <p className='Titre-card'> Bague </p>
                                    <p> Fais avec maya et texturer subtance </p>
                                </div>
                            </div>
                            <div className="product-image-modeling">
                                <div className="box-image-modeling">
                                    <div className='margin-img-modeling'>
                                        <img src={require('../images/Bague_V3.png')}
                                             alt="3Ds-Max"
                                             className="img-product-modeling"/>
                                    </div>
                                    <Link className='link-card-bague' to="">
                                        <p className='p-lien-bague'> Voir plus </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-modeling">
                        <div className="container-image-modeling">
                            <img src={require('../images/Guitare-v1.png')}
                                 alt="3Ds-Max"
                                 className="image-circle-modeling-guitare"/>
                        </div>
                        <div className="content-modeling">
                            <div className="detail-modeling">
                                <div className='p-card-modeling'>
                                    <p className='Titre-card'> Guitare </p>
                                    <p> Fais avec maya et texture en cours </p>
                                </div>
                            </div>
                            <div className="product-image-modeling">
                                <div className="box-image-modeling">
                                    <div className='margin-img-modeling-guitare'>
                                        <img src={require('../images/Guitare-v1.png')}
                                             alt="3Ds-Max"
                                             className="img-product-modeling-guitare"/>
                                    </div>
                                    <Link className='link-card-bague' to="">
                                    <p className='p-lien-bague'> Voir plus </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="codding">
                <p className='Titre-modeling'> Codding </p>
                <div className='container-codding'>
                    <div className="card-codding">
                        <div className="face face1">
                            <div className="content-codding">
                                <img src={require('../images/Codding.png')}
                                     alt="3Ds-Max"
                                     className="img-codding"/>
                            </div>
                        </div>
                        <div className="face face2">
                            <div className="content-codding">
                                <p className='titre-card-codding'> Lorem ipsum </p>
                                <p className='p-card-codding'>Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Quas cum cumque minus
                                    iste veritatis provident at</p>
                                <Link className='link-card-codding' to="">
                                    <p className='p-lien-codding'> Read More </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-codding">
                        <div className="face face1">
                            <div className="content-codding">
                                <img src={require('../images/Codding.png')}
                                     alt="3Ds-Max"
                                     className="img-codding"/>
                            </div>
                        </div>
                        <div className="face face2">
                            <div className="content-codding">
                                <p className='titre-card-codding'> Lorem ipsum </p>
                                <p className='p-card-codding'>Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Quas cum cumque minus
                                    iste veritatis provident at.</p>
                                <Link className='link-card-codding' to="">
                                    <p className='p-lien-codding'> Read More </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-codding">
                        <div className="face face1">
                            <div className="content-codding">
                                <img src={require('../images/Codding.png')}
                                     alt="3Ds-Max"
                                     className="img-codding"/>
                            </div>
                        </div>
                        <div className="face face2">
                            <div className="content-codding">
                                <p className='titre-card-codding'> Lorem ipsum </p>
                                <p className='p-card-codding'>Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Quas cum cumque minus
                                    iste veritatis provident at.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;