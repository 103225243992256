import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';

import CollitoolPage from "./pages/CollitoolPage";
import LightoolPage from "./pages/Lightool-Page";
import NomentoolPage from "./pages/Nomentool";
import ToolingPage from "./pages/ToolingPage";

import './App.css';
import './styles/Navbar-style.css';

function App() {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        if(window.innerWidth >= 820) return;
        if (!isChecked) {
            document.body.style.overflow = 'hidden'; // empêche le défilement
        } else {
            document.body.style.overflow = 'auto'; // permet le défilement
        }
        console.log('Changing bitch')
        setIsChecked(!isChecked);
    }

    useEffect(() => {
        setIsChecked(false)
        return () => {
            document.body.style.overflow = 'auto'; // permet le défilement lorsque le composant est démonté
        };
    }, []);
    return (
        <Router>
            <div>
                <nav className="navbar">
                    <li className="logo"><Link to="/about">
                        <img src={require('./images/Logo.png')}
                             alt="3Ds-Max"
                             className="logo-img"/>
                    </Link></li>
                    <div className={`nav-links ${isChecked ? 'open' : ''}`}>
                        <ul>
                            <li><Link to="/" onClick={handleCheckboxChange}> Home </Link></li>
                            <li><Link to="/tooling" onClick={handleCheckboxChange}> Tooling </Link></li>
                            <li><Link to="/about" onClick={handleCheckboxChange}> About </Link></li>
                        </ul>
                    </div>
                    <div className="navbarButton">
                        <label htmlFor="check" className="menuButton">
                            <input id="check" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                            <span className="top"></span>
                            <span className="mid"></span>
                            <span className="bot"></span>
                        </label>
                    </div>
                </nav>
                <Routes>
                    <Route exact path='/' element={< HomePage/>}></Route>
                    <Route exact path='/about' element={< AboutPage/>}></Route>
                    <Route exact path='/tooling' element={< ToolingPage/>}></Route>

                    <Route exact path='/collitool' element={< CollitoolPage/>}></Route>
                    <Route exact path='/lightool' element={< LightoolPage/>}></Route>
                    <Route exact path='/nomentool' element={< NomentoolPage/>}></Route>
                </Routes>
            </div>
            <footer>
                <div className='line'></div>
                <div className='cercle-logo-social'>
                    <Link className='logo-link' to="https://www.linkedin.com/in/alexandre-nadal-71a097231">
                        <img src={require('./images/Linkedin-logo.png')}
                             alt="Linkedin"
                             className="linkedin-logo"/>
                        <img src={require('./images/Linkedin-logo-blue.png')}
                             alt="Linkedin"
                             className="linkedin-logo-blue"/>
                    </Link>
                </div>
            </footer>
        </Router>
    );
}

export default App;
