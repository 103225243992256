import '../styles/Nomentool.css'
import React from "react";
function nomentoolpage() {
    return (
        <div className="container-nomentool">
            <div className="nomentool">Nomentool</div>

            <div className="background-nomentool">
                <div className="margin-nomentool">
                    <p className="para-tabulation" > Pour optimiser la performance du mesh, il faut récupérer le type de matériaux du mesh (fer,
                        acier, bois, terre, etc.) pour créer un matériel le plus optimisé puis l’assigner à la complexe collision pour
                        qu’il récupère les attributs du mesh. </p>
                    <p className="para-tabulation" > Attention seules les assets qui seront dans le niveau, et non celles qui sont dans le content browser,
                        vont hériter du préfixe </p>
                    <h1> Parameter </h1>
                    <img src={require('../images/Nomentool_Parameter.png')} alt="Nomentool_Parameter" className="Nomentool_Parameter" />
                    <h2> Choose your class </h2>
                    <p className="para-tabulation" > Vous allez devoir sélectionner la class qui va récupérer le préfixe que vous voulez utiliser. </p>
                    <h2> Choose your prefix </h2>
                    <p className="para-tabulation" > Vous allez pouvoir ajouter le nom du préfixe que vous voulez utiliser. Si vous travaillez en groupe
                        vous devez créer une documentation afin de connaitre les préfixes de toutes les class que vous avez
                        choisi. </p>

                    <h1> Limit </h1>
                    <img src={require('../images/Nomentool_Limit.png')} alt="Nomentool_Limit" className="Nomentool_Limit" />
                    <h2> Avertisement Number </h2>
                    <p className="para-tabulation" > Avertisement number permet de créer un avertissement en cas de limite dépassée du nombre
                        d’asset sélectionnées pour éviter que votre logiciel ou ordinateur crash.
                        Il est très important de bien paramétrer la limite car le tool demande beaucoup de puissance quand il
                        rename les assets. </p>
                </div>
            </div>
        </div>
    );
}

export default nomentoolpage;