import '../styles/Collitool.css'
import React from "react";
function CollitoolPage() {
    return (
        <div className="container-collitool">
            <div className="collitool">Collitool</div>

            <div className="background-collitool">
                <div className="margin-collitool">
                    <h1> Selection </h1>
                    <p className="para-tabulation" > Pour commencer vous allez devoir selectionner dans votre content browers tous les statics
                        mesh que vous voulez avoir dans votre selection </p>
                    <div className="column-paragraphe-light-dossier">
                        <div className="collitool-column">
                            <img src={require('../images/Collitool-no-give.png')} alt="Houdini" className="Collitool-no-give" />
                            <img src={require('../images/Collitool-give.png')} alt="Houdini" className="Collitool-give" />
                        </div>

                        <div className="para-column">
                            <h2 className="titre-item-list-principal" > Item list </h2>
                            <p className="para-tabulation-item-list-principal" > Quand vous allez lancer le tool la liste sera
                                vide. Vous allez avoir une item liste où tous
                                vos statics Meshs, que vous avez
                                selectionné, vont aparaître. </p>
                            <h2 className="titre-refresh-principal" > Refresh </h2>
                            <p className="para-tabulation-refresh-principal" > Va vous permettre de mettre à jour la liste
                                des items que vous avez sélectionnés. Si
                                vous n’avez rien sélectionné, il va apparaitre
                                un message d’erreur afin que vous
                                sélectionniez un ou des static mesh
                            </p>
                            <p className="para-tabulation-principal" > Un filtre va permettre de savoir si tout ce
                                que vous avez sélectionné correspond à
                                des statics mesh, si ce n’est pas le cas, un
                                message d’erreur affichera tout ce qui n’est
                                pas compatible. </p>
                        </div>
                    </div>

                    <h2 className="titre-item-list-secondaire" > Item list </h2>
                    <p className="para-tabulation-item-list-secondaire" > Quand vous allez lancer le tool la liste sera
                        vide. Vous allez avoir une item liste où tous
                        vos statics Meshs, que vous avez
                        selectionné, vont aparaître. </p>
                    <h2 className="titre-refresh-secondaire" > Refresh </h2>
                    <p className="para-tabulation-refresh-secondaire" > Va vous permettre de mettre à jour la liste
                        des items que vous avez sélectionnés. Si
                        vous n’avez rien sélectionné, il va apparaitre
                        un message d’erreur afin que vous
                        sélectionniez un ou des static mesh
                    </p>
                    <p className="para-tabulation-secondaire" > Un filtre va permettre de savoir si tout ce
                        que vous avez sélectionné correspond à
                        des statics mesh, si ce n’est pas le cas, un
                        message d’erreur affichera tout ce qui n’est
                        pas compatible. </p>

                    <p className="para-tabulation" > La liste d’item fonctionne grâce à une
                        ListView qui va appeler un widget
                        Blueprint. Ce dernier a été créé
                        préalablement avec trois variabl: </p>
                    <p className="para-tabulation" > - Une variable qui permet d’afficher le Mesh, </p>
                    <p className="para-tabulation" > - Une variable, pour Has Collision, qui permet que s’il y a déjà une Collision sur le mesh, la
                        check box se valide </p>
                    <p className="para-tabulation" > - Une variable pour la précision, utilisée seulement pour la complex collision, afin qu’elle
                        intervienne pour que chaque mesh est son propre niveau de précision </p>

                    <h2> Clear </h2>
                    <p className="para-tabulation" > Va vous permettre de remettre la selection que vous avez faite a 0 </p>

                    <h1> Simple collision </h1>
                    <img src={require('../images/Collitool-simplex-collision.png')} alt="Houdini" className="Collitool-simplex-collision" />
                    <h2> Collision Type </h2>
                    <p className="para-tabulation" > Est un menu deroulant où vous allez pouvoir trouver differents types de collision tres basiques
                        comme des box,des spheres,ou des capsules </p>
                    <h2> Apply </h2>
                    <p className="para-tabulation" > Va permettre d’ajouter la collision choisie à tous les mesh sélectionnés  </p>
                    <h2> Remove </h2>
                    <p className="para-tabulation" > Va enlever tous types de collision sur le mesh  </p>

                    <h1> Convex collision </h1>
                    <img src={require('../images/Collitool-convex-collision.png')} alt="Houdini" className="Collitool-convex-collision" />
                    <h2> Hull Count </h2>
                    <p className="para-tabulation" > Hull Count va définir le nombre de polygone à créer pour reproduire le plus fidèlement le static
                        mesh sélectionné </p>
                    <h2> Max Hull Verts </h2>
                    <p className="para-tabulation" > Max Hull Verts définit la complexité des polygones qui vont être utilisé pour créer la collision  </p>
                    <h2> Hull Précision </h2>
                    <p className="para-tabulation" > Hull Précision va permettre d’être le plus précis possible pour créer la collision par rapport au
                        mesh  </p>
                    <h2> Apply </h2>
                    <p className="para-tabulation" > Va permettre d’ajouter la collision choisie à tous les mesh sélectionnés  </p>
                    <h2> Remove </h2>
                    <p className="para-tabulation" > Va enlever tous types de collision sur le mesh  </p>

                    <h1> Complex collision </h1>
                    <img src={require('../images/Collitool-complex-collision.png')} alt="Houdini" className="Collitool-complex-collision" />
                    <h2> For all </h2>
                    <p className="para-tabulation" > Si vous cochez For All vous utiliserez la même precision pour tous les mesh selectionnés
                        Si vous ne cochez pas For All vous gèrerez la préscision de chaque mesh dans la liste au dessus </p>
                    <h2> Precision </h2>
                    <p className="para-tabulation" > Cela permet de reproduire le mesh le plus fidélement possible, avec une échelle de précision où
                        le chiffre 1 correspond à la reproduction identique du mesh </p>
                    <h2> Apply </h2>
                    <p className="para-tabulation" > Quand vous allez cliquer sur apply cela va créer un autre statics mesh avec la précision qui a été
                        choisie auparavant et qui va être assigné à la complexe collision du mesh de base. </p>
                    <p className="para-tabulation" > Pour optimiser la performance du mesh, il faut récupérer le type de matériaux du mesh (fer,
                        acier, bois, terre, etc.) pour créer un matériel le plus optimisé puis l’assigner à la complexe collision pour
                        qu’il récupère les attributs du mesh. </p>
                </div>
            </div>
        </div>
    );
}

export default CollitoolPage;