// pages/Home.js
import { Link } from 'react-router-dom';
import '../styles/ToolingPage.css'
import '../App.css'

import React, { useRef, useEffect } from "react";

function ToolingPage() {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleVideoEnded = () => {
            videoElement.currentTime = 0;  // Reset video to start
            videoElement.play();
        };

        videoElement.addEventListener('ended', handleVideoEnded);

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnded);
        };
    }, []);

    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;  // Reset video to start
        }
    };
    return (
        <div className='ToolingPage'>
            <div className="Unreal">
                    <div className="cont-unreal">
                        <div className="pair-container">
                            <div className="card-unreal"></div>
                            <div className="column-unreal">
                                <h1 className="titre-collitool-unreal">Collitool</h1>
                                <div className="para-collitool-unreal">
                                    <p className="first-para">Collitool est un outil développé sur Unreal Engine. Son
                                        principal objectif est de faciliter l'ajout de collisions sur de multiples
                                        assets
                                        simultanément. De plus, il permet de sélectionner le type de collision : simple,
                                        complexe ou convexe.</p>
                                    <p>En utilisant l'option "complexe", l'outil utilise une version basse résolution du
                                        mesh pour la collision. De cette façon, il optimise les performances sans
                                        compromettre la précision de la collision. De plus, Collitool vous permet de
                                        remplacer ou de supprimer des collisions existantes, offrant ainsi une
                                        flexibilité
                                        considérable dans la gestion des collisions.</p>
                                    <p>En outre, si un type spécifique de matériau est utilisé, Collitool appliquera
                                        également un matériau correspondant à la collision. Cette fonctionnalité
                                        améliore la
                                        performance du rendu final.</p>
                                </div>
                                <Link className="link-card-tooling" to="/collitool">
                                    <div className="p-link-tooling"> Voir plus </div>
                                </Link>
                            </div>
                        </div>

                        <div className="pair-container">
                            <div className="card-unreal"></div>
                            <div className="column-unreal">
                                <h1 className="titre-collitool-unreal">Nomentool</h1>
                                <div className="para-collitool-unreal">
                                    <p className="first-para">NomenTool est un outil développé sur Unreal Engine qui
                                        permet
                                        de créer des nomenclatures en sélectionnant simplement les assets auxquels vous
                                        souhaitez ajouter un préfixe. Il offre également la possibilité de créer des
                                        dossiers pour organiser et ranger vos assets de manière propre et structurée.
                                        Grâce
                                        à NomenTool, la gestion et l'organisation des assets dans vos projets Unreal
                                        deviennent plus simples et efficaces, vous permettant de gagner du temps et
                                        d'améliorer votre flux de travail.</p>
                                </div>
                                <Link className="link-card-tooling" to="/nomentool">
                                    <div className="p-link-tooling"> Voir plus</div>
                                </Link>
                            </div>
                        </div>

                        <div className="pair-container">
                            <div className="card-unreal" onMouseEnter={handleMouseEnter}
                                 onMouseLeave={handleMouseLeave}>
                                <video
                                        ref={videoRef}
                                        src={require('../videos/LighTool_3.mp4')}
                                        className="video_light"
                                ></video>
                            </div>
                            <div className="column-unreal">
                                <h1 className="titre-collitool-unreal">Lightool</h1>
                                <div className="para-collitool-unreal">
                                    <p className="first-para">Lightool est un outil développé pour Unreal Engine qui
                                        permet
                                        de manipuler efficacement l'éclairage grâce à un Blueprint Actor. Vous pouvez y
                                        ajouter un Static Mesh de votre choix, puis le placer directement dans votre
                                        scène.</p>
                                    <p>Une fois le Static Mesh ajouté, Lightool vous permet de modifier la couleur,
                                        l'intensité et la température de l'éclairage. Ces modifications seront
                                        appliquées à
                                        la fois au matériau et aux lumières, si elles sont incluses dans le
                                        Blueprint.</p>
                                    <p>En outre, Lightool offre la possibilité de créer des effets de scintillement pour
                                        vos
                                        lumières. Pour cela, il suffit de créer une courbe, de la convertir en texture,
                                        puis
                                        d'ajuster les paramètres de vitesse et d'intensité. Ensuite, il ne vous reste
                                        plus
                                        qu'à ajouter ce paramètre à la lumière de votre choix.</p>
                                </div>
                                <Link className="link-card-tooling" to="/lightool">
                                    <div className="p-link-tooling"> Voir plus </div>
                                </Link>
                            </div>
                        </div>

                        <div className="pair-container">
                            <Link to="">
                                <div className="card-unreal"></div>
                            </Link>
                            <div className="column-unreal">
                                <h1 className="titre-collitool-unreal">Roadtool</h1>
                                <div className="para-collitool-unreal">
                                    <p className="first-para">Roadtool est un outil développé sur Houdini qui est
                                        utilisable sur Unreal. Cet outil permet de créer automatiquement des routes
                                        sur un paysage. Le terrain s'adapte : si le terrain est trop incliné, un
                                        tunnel sera créé ; si le terrain présente un trou, un pont sera construit ;
                                        si le terrain est plat, la route se collera directement à celui-ci. Cet
                                        outil est toujours en développement. </p>
                                    <p>Pour l'instant, je n'ai pas encore ajouté la fonctionnalité de création de
                                        ponts, mais tout le reste fonctionne très bien.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default ToolingPage;
